<template>
  <div>
    <l-card
      v-if="organizationBillingOptions"
      :title="$t('billing-options.title')"
    >
      <div class="text-blue flex items-center justify-between">
        {{ $t('billing-options.is-business-plan') }}
        <l-switch
          :value="organizationBillingOptions.businessPlanActive"
          :disabled="organizationBillingOptions.businessPlanActive"
          class="ml-2"
          @input="enableBusinessPlan"
        />
      </div>
      <div v-if="organizationBillingOptions.businessPlanActive">
        <l-editable-value
          :label="$t('billing-options.base-subscription')"
          :value="
            (organizationBillingOptions.posSubscriptionPrice / 100).toFixed(2)
          "
          icon="euro"
          class="py-4"
          @input="value => updateBaseSubscriptionPrice(value)"
        />
        <div class="separator bg-gray-200" />
        <l-editable-ranges
          :label="$t('billing-options.extra-tabs-prices')"
          :ranges="extraPriceRanges"
          currency="euro"
          placeholder="orders"
          :currency-decimals="3"
          :ranges-limit="1"
          class="text-blue"
          @input="ranges => updateExtraTabsPrices(ranges)"
        />
        <div class="separator bg-gray-200" />
        <l-editable-value
          :label="$t('billing-options.devices-limit')"
          :value="organizationBillingOptions.devicesLimit.toString()"
          class="py-4"
          @input="value => updateDevicesLimit(value)"
        />
        <div class="separator bg-gray-200" />
        <l-editable-value
          :label="$t('billing-options.integrations-limit')"
          :value="organizationBillingOptions.integrationsLimit.toString()"
          class="py-4"
          @input="value => updateIntegrationsLimit(value)"
        />
        <div class="separator bg-gray-200" />
        <l-editable-value
          :label="$t('billing-options.shop-commission')"
          :value="organizationBillingOptions.shopCommission.toString()"
          icon="percentage"
          class="py-4"
          @input="value => updateShopCommission(value)"
        />
        <div class="separator bg-gray-200" />
        <l-editable-value
          :label="$t('billing-options.qr-commission')"
          :value="organizationBillingOptions.qrCommission.toString()"
          icon="percentage"
          class="py-4"
          @input="value => updateQRCommission(value)"
        />
        <l-editable-value
          :label="$t('billing-options.dataphone-commission')"
          :value="organizationBillingOptions.dataphoneCommission.toString()"
          icon="percentage"
          class="py-4"
          @input="value => updateDataphoneCommission(value)"
        />
      </div>
    </l-card>
  </div>
</template>

<script>
import LCard from '@last/core-ui/components/LCard'
import LSwitch from '@last/core-ui/components/LSwitch.vue'
import LEditableValue from '@last/core-ui/components/LEditableValue'
import LEditableRanges from '@last/core-ui/components/LEditableRanges'
import api from '@/api.js'
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'OrganizationBillingOptions',
  components: {
    LCard,
    LEditableValue,
    LEditableRanges,
    LSwitch
  },
  props: {},
  data() {
    return {
      organizationBillingOptions: null
    }
  },
  computed: {
    ...mapState('organizations', ['selectedOrganization']),
    extraPriceRanges() {
      return [
        {
          units: this.organizationBillingOptions.tabsLimit,
          price: this.organizationBillingOptions.extraTabsPrice
        }
      ]
    }
  },
  watch: {
    async selectedOrganization() {
      await this.refreshOrganizationBillingOptions()
    }
  },
  async mounted() {
    await this.refreshOrganizationBillingOptions()
  },
  methods: {
    async refreshOrganizationBillingOptions() {
      if (this.selectedOrganization) {
        let result = await api.get(
          `/support/organization/${this.selectedOrganization.id}/billing-options`
        )
        this.organizationBillingOptions = {
          ...result.data
        }
        Vue.set(this, 'organizationBillingOptions', result.data)
      }
    },
    async updateBillingOptions() {
      try {
        await api.put(
          `/support/organization/${this.selectedOrganization.id}/billing-options`,
          {
            billingOptions: this.organizationBillingOptions
          }
        )
      } catch (e) {
        this.$lnotification.create({
          title: this.$t('error'),
          iconColor: 'red'
        })
      }
    },
    async updateBaseSubscriptionPrice(value) {
      this.organizationBillingOptions.posSubscriptionPrice = Number(value) * 100
      this.updateBillingOptions()
    },
    async updateShopCommission(value) {
      this.organizationBillingOptions.shopCommission = Number(value)
      this.updateBillingOptions()
    },
    async updateQRCommission(value) {
      this.organizationBillingOptions.qrCommission = Number(value)
      this.updateBillingOptions()
    },
    async updateDataphoneCommission(value) {
      const commission = Number(value)
      const minimumCommission = 0.6
      if (commission < minimumCommission) {
        this.showCommissionError(minimumCommission)
        return
      }
      this.organizationBillingOptions.dataphoneCommission = commission
      this.updateBillingOptions()
    },
    async updateIntegrationsLimit(value) {
      this.organizationBillingOptions.integrationsLimit = Number(value)
      this.updateBillingOptions()
    },
    async updateDevicesLimit(value) {
      this.organizationBillingOptions.devicesLimit = Number(value)
      this.updateBillingOptions()
    },
    async updateExtraTabsPrices(ranges) {
      const { price, units } = ranges[0]
      this.organizationBillingOptions.extraTabsPrice = price
      this.organizationBillingOptions.tabsLimit = units
      this.updateBillingOptions()
    },
    async enableBusinessPlan() {
      try {
        await api.put(
          `/support/organization/${this.selectedOrganization.id}/billing-options/enable-business-plan`
        )
        await this.refreshOrganizationBillingOptions()
        this.organizationBillingOptions.businessPlanActive = true
      } catch (e) {
        this.$lnotification.create({
          title: this.$t('error'),
          iconColor: 'red'
        })
      }
    },
    showCommissionError(minValue) {
      this.$lnotification.create({
        title: this.$t('billing-options.commission-error-title'),
        subtitle: this.$t('billing-options.commission-error-message', {
          value: minValue
        }),
        icon: 'close',
        iconColor: 'red'
      })
    }
  }
}
</script>

<style scoped>
::v-deep .separator {
  height: 1px;
}
</style>
