<template>
  <div class="flex justify-between items-center">
    <div class="flex-1 mr-6">
      <l-field
        :label="$t('import-external-catalog.name')"
        label-font-size-class="text-xs"
      >
        <l-input
          v-model="catalogName"
          :placeholder="$t('import-external-catalog.name-placeholder')"
          class="text-sm"
          :disabled="importing"
        />
      </l-field>
    </div>
    <div class="flex-1 mr-6">
      <l-field
        :label="$t('import-external-catalog.url')"
        label-font-size-class="text-xs"
        class="uppercase"
      >
        <l-input
          v-model="url"
          :placeholder="$t('import-external-catalog.url-placeholder')"
          class="text-sm"
          :disabled="importing"
        />
      </l-field>
    </div>
    <div
      v-if="!importing"
      class="border border-violet text-violet rounded-lg w-1/4 text-center py-2 flex justify-center"
      :class="{ 'opacity-50': !valid, 'cursor-pointer': valid }"
      @click="importCatalog"
    >
      {{ $t('import-external-catalog.import') }}
    </div>
    <div v-else class="text-blue text-center flex w-1/4 justify-center">
      <div class="w-6 mr-4">
        <lottie-animation path="lottie-loading.json" />
      </div>
      {{ $t('import-external-catalog.importing') }}
    </div>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput.vue'
import LField from '@last/core-ui/components/LField.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import validator from 'validator'
import { lastUtils } from '@last/core'

export default {
  name: 'ImportExternalCatalog',
  components: {
    LInput,
    LField,
    LottieAnimation
  },
  emits: ['import-requested'],
  props: {
    source: {
      type: String,
      required: true
    },
    importing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      catalogName: null,
      url: null
    }
  },
  computed: {
    valid() {
      return (
        !lastUtils.isNullOrEmpty(this.catalogName) && !lastUtils.isNullOrEmpty(this.url) && validator.isURL(this.url)
      )
    }
  },
  methods: {
    importCatalog() {
      if (this.isValid()) {
        this.$emit('import-requested', {
          name: this.catalogName,
          url: this.url
        })
      }
    },
    isValid() {
      return (
        !lastUtils.isNullOrEmpty(this.catalogName) && !lastUtils.isNullOrEmpty(this.url) && validator.isURL(this.url)
      )
    }
  }
}
</script>

<style scoped></style>
