var StringParser = /** @class */ (function () {
    function StringParser() {
    }
    StringParser.removeEmojis = function (input) {
        var emojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD00-\uDDFF]|\uD83E[\uDE00-\uDEFF]|[\u2011-\u26FF]|[\u200D]|\uFE0F)/g;
        return input.replace(emojiRegex, '').trim();
    };
    StringParser.removeEmojisFromObject = function (obj) {
        var result = {};
        for (var key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'string') {
                    result[key] = this.removeEmojis(obj[key]);
                }
                else if (Array.isArray(obj[key])) {
                    result[key] = this.removeEmojisFromArray(obj[key]);
                }
                else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    result[key] = this.removeEmojisFromObject(obj[key]);
                }
                else {
                    result[key] = obj[key];
                }
            }
        }
        return result;
    };
    StringParser.removeEmojisFromArray = function (arr) {
        var _this = this;
        return arr.map(function (item) {
            if (typeof item === 'string') {
                return _this.removeEmojis(item);
            }
            else if (Array.isArray(item)) {
                return _this.removeEmojisFromArray(item);
            }
            else if (typeof item === 'object' && item !== null) {
                return _this.removeEmojisFromObject(item);
            }
            else {
                return item;
            }
        });
    };
    return StringParser;
}());
export default StringParser;
