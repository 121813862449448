<template>
  <div class="flex justify-between items-center">
    <div class="flex-1 mr-6">
      <l-field
        :label="$t('import-external-catalog.name')"
        label-font-size-class="text-xs"
      >
        <l-input
          v-model="catalogName"
          :placeholder="$t('import-external-catalog.name-placeholder')"
          class="text-sm"
          :disabled="importing"
        />
      </l-field>
    </div>
    <div class="flex-1 mr-6">
      <l-field
        :label="$t('import-catalog.csv')"
        label-font-size-class="text-xs"
      >
        <l-upload
          @input="fileUpdated"
          :disabled="false"
          :drag-drop="true"
          :accept="'.csv'"
          class="flex-1 my-2 h-12"
        >
          <div
            v-if="!file"
            class="flex items-center justify-center flex-1 text-sm text-cente text-center cursor-pointer p-2"
          >
            <icon name="upload" class="text-violet mr-3" />
            <span v-html="$t('import-csv-catalog.upload-file')"></span>
          </div>
          <div
            v-else
            class="flex flex-1 items-center p-2 text-sm text-center text-blue rounded-small"
          >
            <div class="flex-shrink-0 cursor-pointer mr-2">
              <icon name="catalog" class="text-blue" />
            </div>
            <div class="flex-1 text-blue text-left">
              {{ file.name }}
            </div>
            <div
              @click.prevent="resetInput"
              class="flex-shrink-0 cursor-pointer"
            >
              <icon name="close" class="text-blue" />
            </div>
          </div>
        </l-upload>
      </l-field>
    </div>
    <div
      v-if="!importing"
      class="border border-violet text-violet rounded-lg w-1/4 text-center py-2 flex justify-center"
      :class="{ 'opacity-50': !valid, 'cursor-pointer': valid }"
      @click="importCatalog"
    >
      {{ $t('import-external-catalog.import') }}
    </div>
    <div v-else class="text-blue text-center flex w-1/4 justify-center">
      <div class="w-6 mr-4">
        <lottie-animation path="lottie-loading.json" />
      </div>
      {{ $t('import-external-catalog.importing') }}
    </div>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput.vue'
import LUpload from '@last/core-ui/components/LUpload.vue'
import LField from '@last/core-ui/components/LField.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { lastUtils } from '@last/core'
import Icon from '@last/core-ui/components/Icon.vue'
import { mapState } from 'vuex'
import api from '@/api.js'

export default {
  name: 'ImportCSVCatalog',
  components: {
    LInput,
    LUpload,
    LField,
    LottieAnimation,
    Icon
  },
  data() {
    return {
      catalogName: null,
      csv: null,
      file: null,
      importing: false
    }
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    ...mapState('organizations', ['selectedOrganization']),
    valid() {
      return (
        !lastUtils.isNullOrEmpty(this.catalogName) &&
        !lastUtils.isNullOrEmpty(this.csv)
      )
    }
  },
  methods: {
    async fileUpdated(file) {
      this.file = file
      this.csv = await file.text()
    },
    isValid() {
      return (
        !lastUtils.isNullOrEmpty(this.catalogName) &&
        !lastUtils.isNullOrEmpty(this.csv)
      )
    },
    resetInput() {
      this.catalogName = null
      this.file = null
      this.csv = null
    },
    async uploadFile(file) {
      if (this.loading) return
      this.loading = true
      const formData = new FormData()
      formData.append('file', file)
      this.loading = false
    },
    async importCatalog() {
      if (this.isValid()) {
        this.importing = true
        try {
          await api.post(`support/csv-catalog-import`, {
            csv: this.csv,
            catalogName: this.catalogName,
            organizationId: this.selectedOrganization.id
          })
          this.resetInput()
          window.open(
            `${process.env.VUE_APP_LAST_ADMIN_URL}/support/login?supportToken=${this.accessToken}&organizationId=${this.selectedOrganization.id}&redirect=catalogs`
          )
        } catch (err) {
          this.$lnotification.create({
            title: `${this.$t('import-external-catalog.import-failed')}: ${
              err.response.request.response
            }`,
            icon: 'close',
            iconColor: 'red'
          })
        }
        this.importing = false
      }
    }
  }
}
</script>

<style scoped></style>
