<template>
  <div @click="action" :class="{ 'cursor-pointer': notification.onAction }">
    <div
      class="flex flex-col shadow mb-3 transparent-background rounded-xl py-4 px-6 z-50"
    >
      <div class="flex items-center justify-between">
        <div
          :class="'bg-' + (notification.iconColor || 'gray-400')"
          class="rounded-full p-2"
        >
          <icon
            :name="notification.icon || 'default-notification'"
            class="text-white"
          />
        </div>
        <div class="flex flex-col flex-1 ml-4 py-2">
          <strong class="text-white">{{ notification.title }}</strong>
        </div>
        <div
          v-if="
            notification.type === 'progress-bar' && notification.progress < 100
          "
          class="flex justify-end text-white cursor-pointer"
          @click="cancelProgress"
        >
          <div>{{ $t('notification.cancel') }}</div>
        </div>
        <div
          v-else-if="!allowBlockDelete || !notification.blockDelete"
          class="flex justify-end w-12"
        >
          <icon
            name="close"
            @click="deactivate"
            class="text-red cursor-pointer"
          />
        </div>
      </div>
      <div
        v-if="notification.type === 'progress-bar'"
        class="flex items-center justify-between py-3"
      >
        <div class="px-2">
          <div class="w-6"></div>
        </div>
        <div class="w-full h-4 bg-violet-100 rounded-full">
          <div
            class="flex items-center justify-center h-4 bg-violet rounded-full progress"
            :style="progressStyles"
          >
            <div
              v-if="notification.progress >= 10"
              class="text-center text-xs text-white"
            >
              {{ notification.progress }}%
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="notification.subtitle || notification.createdAt"
        class="flex items-center justify-between"
      >
        <div class="px-2">
          <div class="w-6"></div>
        </div>
        <div class="flex text-sm text-white w-64 ml-4 capitalize-first">
          <div v-if="notification.subtitleIcon" class="pr-2">
            <icon
              :name="notification.subtitleIcon || 'default-notification'"
              class="text-white"
            />
          </div>
          <div>{{ notification.subtitle }}</div>
        </div>
        <div class="flex justify-end w-12">
          <div v-if="notification.keepInScreen" class="text-sm text-gray-400">
            {{ timeAgo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import moment from 'moment'
import { lastUtils } from '@last/core'

export default {
  name: 'LNotification',
  emits: ['notificationDeleted', 'progressCanceled'],
  components: {
    Icon
  },
  props: {
    notification: {
      type: Object,
      default: null
    },
    allowBlockDelete: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timeAgo: '',
    interval: null
  }),
  unmounted() {
    clearInterval(this.interval)
  },
  mounted() {
    this.calculateTimeAgo()
    this.interval = setInterval(this.calculateTimeAgo, 60000)
    if (
      !this.notification.keepInScreen &&
      this.notification.type !== 'progress-bar'
    ) {
      setTimeout(this.deactivate, this.notification.timeout || 3000)
    }
  },
  computed: {
    progressStyles() {
      return {
        width: `${this.notification.progress}%`
      }
    }
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow()
    }
  },
  methods: {
    deactivate() {
      this.$emit('notificationDeleted')
    },
    action() {
      if (this.notification.onAction) {
        this.notification.onAction()
        this.deactivate()
      }
    },
    calculateTimeAgo() {
      this.timeAgo = moment(this.notification.createdAt).fromNow()
    },
    cancelProgress() {
      this.$ldialog({
        title: this.notification.progressCancelTitle,
        icon: 'alert',
        mainLabel: this.$t('notification.cancel-progress'),
        secondaryLabel: this.$t('notification.continue-progress'),
        onConfirm: async () => {
          this.$emit('progressCanceled')
          this.deactivate()
        }
      })
    }
  },
  watch: {
    notification: {
      async handler(notification) {
        if (notification.progress >= 100) {
          await lastUtils.sleep(500)
          this.deactivate()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.transparent-background {
  background-color: rgba(64, 63, 100, 0.8);
  backdrop-filter: blur(0.625rem);
}
.rounded-xl {
  border-radius: 1rem;
}
.capitalize-first::first-letter {
  text-transform: uppercase;
}
.progress {
  transition: 0.4s linear;
  transition-property: width;
}
</style>
