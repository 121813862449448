<template>
  <div class="px-5 pt-8">
    <l-card
      :title="$t('pos-error-manager.title')"
      :overflow-visible="true"
      :content-padding="true"
    >
      <div>
        <div
          v-if="activeErrorMessages.length > 0"
          class="text-gray-400 uppercase"
        >
          {{ $t('pos-error-manager.active-errors') }}
          {{ $t('pos-error-manager.active-errors') }}
        </div>
        <div class="flex flex-wrap">
          <div
            v-for="error in activeErrorMessages"
            :key="error.id"
            class="w-1/2"
          >
            <l-card
              :title="error.title"
              :description="error.description"
              :subtitle="errorSubtitle(error)"
              border-class="border border-violet"
              description-class="text-blue"
              :disabled="editingError.id && editingError.id !== error.id"
            >
              <template #top-right>
                <div class="flex">
                  <icon
                    name="pen"
                    class="cursor-pointer mr-4 text-gray-400"
                    @click="setEditingError(error)"
                  />
                  <icon
                    name="trash"
                    class="text-gray-400 cursor-pointer"
                    @click="deleteError(error.id)"
                  />
                </div>
              </template>
            </l-card>
          </div>
        </div>
        <div
          class="flex justify-between items-center border-gray-300"
          :class="{ 'border-t pt-4': activeErrorMessages.length > 0 }"
        >
          <div class="flex-1">
            <l-field
              :label="$t('pos-error-manager.error-title')"
              label-font-size-class="text-xs"
            >
              <l-input
                v-model="editingError.title"
                :placeholder="$t('pos-error-manager.error-title-placeholder')"
                class="text-sm"
                :disabled="false"
              />
            </l-field>
          </div>
        </div>
        <div class="">
          <l-field
            :label="$t('pos-error-manager.message-audience')"
            label-font-size-class="text-xs"
          />
          <div class="flex flex-1 mb-4 -mt-2">
            <div class="flex text-xs">
              <l-radio
                :value="editingError.allLocations === true"
                :label="$t('pos-error-manager.all-locations')"
                @input="setToAllLocations"
                label-class="text-xs"
              />
            </div>
            <div class="flex ml-6">
              <l-radio
                :value="editingError.allLocations === false"
                :label="$t('pos-error-manager.by-integration')"
                @input="editingError.allLocations = false"
                label-class="text-xs"
              />
            </div>
          </div>
          <l-select
            :placeholder="$t('pos-error-manager.integrations-placeholder')"
            :options="allIntegrations"
            option-label="name"
            option-value="name"
            v-model="editingError.affectedIntegrations"
            multiselect
            class="text-xs text-gray-200"
            theme="light"
            :disabled="editingError.allLocations"
          />
        </div>
        <div class="w-full mt-6">
          <l-field
            :label="$t('pos-error-manager.problem-description')"
            label-font-size-class="text-xs"
          >
            <l-input
              v-model="editingError.description"
              :placeholder="
                $t('pos-error-manager.problem-description-placeholder')
              "
              class="text-sm"
              maxlength="512"
              input-type="textarea"
            />
          </l-field>
        </div>
        <div class="flex justify-end">
          <div
            class="border border-violet text-violet rounded-lg w-1/4 text-center py-2 flex justify-center cursor-default"
            :class="{ 'opacity-50': !isComplete, 'cursor-pointer': isComplete }"
            @click="publishError"
          >
            {{ $t('pos-error-manager.publish-error') }}
          </div>
        </div>
      </div>
    </l-card>
  </div>
</template>

<script>
import LCard from '@last/core-ui/components/LCard.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LField from '@last/core-ui/components/LField.vue'
import LSelect from '@last/core-ui/components/LSelect.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LRadio from '@last/core-ui/components/LRadio.vue'
import { lastUtils } from '@last/core'
import api from '@/api.js'

export default {
  name: 'PosErrorManager',
  components: {
    LCard,
    LInput,
    LField,
    LSelect,
    Icon,
    LRadio
  },
  data() {
    return {
      loading: false,
      editingError: {
        id: null,
        title: null,
        affectedIntegrations: [],
        allLocations: false,
        description: null
      },
      allIntegrations: [
        { name: 'Uber' },
        { name: 'Glovo' },
        { name: 'Deliveroo' },
        { name: 'JustEat' },
        { name: 'Stuart' },
        { name: 'Shargo' },
        { name: 'Deelivers' },
        { name: 'GlovoOnDemand' },
        { name: 'Paack' },
        { name: 'Cabify' },
        { name: 'CloserLogistics' },
        { name: 'Mox' },
        { name: 'Paycomet' }
      ],
      activeErrorMessages: []
    }
  },
  async mounted() {
    await this.refreshMessages()
  },
  computed: {
    isComplete() {
      return (
        !lastUtils.isNullOrEmpty(this.editingError.title) &&
        (this.editingError.affectedIntegrations.length > 0 ||
          this.editingError.allLocations) &&
        !lastUtils.isNullOrEmpty(this.editingError.description)
      )
    }
  },
  methods: {
    setEditingError(error) {
      this.editingError.id = error.id
      this.editingError.title = error.title
      this.editingError.description = error.description
      this.editingError.affectedIntegrations = error.affectedIntegrations
      this.editingError.allLocations = error.allLocations
    },
    deleteError(id) {
      if (this.loading) return
      this.loading = true
      this.$ldialog({
        title: this.$t('pos-error-manager.delete-error-message-title'),
        content: this.$t('pos-error-manager.delete-error-message-content'),
        mainLabel: this.$t('ctas.delete'),
        icon: 'trash',
        onConfirm: async () => {
          await api.delete(`/support/error-messages/${id}`)
          await this.refreshMessages()
          this.editingError = {
            id: null,
            title: null,
            affectedIntegrations: [],
            allLocations: false,
            description: null
          }
        }
      })
      this.loading = false
    },
    async publishError() {
      if (!this.isComplete) {
        return
      }
      if (this.loading) return
      this.loading = true
      if (this.editingError.id) {
        await api.put(
          `/support/error-messages/${this.editingError.id}`,
          this.editingError
        )
      } else {
        const response = await api.post(
          `/support/error-messages`,
          this.editingError
        )
        this.editingError.id = response.data.id
      }
      await this.refreshMessages()
      this.loading = false
      this.editingError = {
        id: null,
        title: null,
        affectedIntegrations: [],
        allLocations: false,
        description: null
      }
    },
    async refreshMessages() {
      const res = await api.get(`/support/error-messages`)
      this.activeErrorMessages = res.data.messages
    },
    errorSubtitle(error) {
      return error.allLocations
        ? 'ALL LOCATIONS'
        : error.affectedIntegrations.join(',')
    },
    setToAllLocations() {
      this.editingError.allLocations = true
      this.editingError.affectedIntegrations = []
    }
  }
}
</script>

<style scoped></style>
