var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import VATValidator from './VATValidator';
import moment from 'moment';
export var supportedCountries = ['ES', 'DE'];
export var colors = [
    '#FF7878',
    '#7142A0',
    '#D55283',
    '#35BF8D',
    '#E55934',
    '#4299E1',
    '#3A6890',
    '#21BECB',
    '#FFD166',
    '#795548',
    '#5F3C54'
];
export function currencyFormatters(currency, milis) {
    if (milis === void 0) { milis = false; }
    var config = {
        style: 'currency',
        currency: currency !== null && currency !== void 0 ? currency : 'EUR',
        maximumFractionDigits: milis ? 3 : 2
    };
    var formats = {
        EUR: 'es-ES',
        USD: 'en-EN',
        GBP: 'en-EN'
    };
    return new Intl.NumberFormat(formats[currency], config);
}
export function colorIsDark(hexcolor) {
    var color = hexcolor.substr(1, 6);
    var r = parseInt(color.substring(0, 2), 16);
    var g = parseInt(color.substring(2, 4), 16);
    var b = parseInt(color.substring(4, 6), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 200;
}
export function darken(color, percent) {
    if (percent === void 0) { percent = 10; }
    if (!color)
        return '#1D1C48';
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);
    R = Math.min(Math.floor((R * (100 - percent)) / 100), 255);
    G = Math.min(Math.floor((G * (100 - percent)) / 100), 255);
    B = Math.min(Math.floor((B * (100 - percent)) / 100), 255);
    var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);
    return '#' + RR + GG + BB;
}
export function stringHashCode(string) {
    var hash = 0, i, chr;
    if (string.length === 0)
        return hash;
    for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}
export function log(item) {
    console.log(item); // eslint-disable-line no-console
    return item;
}
export function getHashColor(string) {
    return colors[stringHashCode(string) % colors.length];
}
export function getNthColor(n) {
    return colors[n % colors.length];
}
export function round(num) {
    return Math.round(num * 100) / 100;
}
export function deepClone(data) {
    return JSON.parse(JSON.stringify(data));
}
export function sleep(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
export function currencyFilter(value, currency, milis) {
    if (currency === void 0) { currency = 'EUR'; }
    if (milis === void 0) { milis = false; }
    var decimalValue = milis ? value / 1000 : value / 100;
    if (!['USD', 'GBP', 'EUR'].includes(currency)) {
        return currencyFormatters('EUR', milis).format(decimalValue);
    }
    return currencyFormatters(currency, milis).format(decimalValue);
}
export function currencySymbol(currency) {
    if (currency === void 0) { currency = 'EUR'; }
    return currencyFilter(0, currency)
        .replace(/\d|[,.]/g, '')
        .trim();
}
export function objectsAreEqual(initialObject, object) {
    if (!initialObject || !object)
        return false;
    return Object.keys(object).reduce(function (acc, key) {
        if (acc && initialObject[key] !== object[key])
            acc = false;
        return acc;
    }, true);
}
export function formatNumber(phoneNumber) {
    if (!phoneNumber)
        return phoneNumber;
    var parsedNumber = parsePhoneNumberFromString(phoneNumber, 'ES');
    return String(parsedNumber ? parsedNumber.number : phoneNumber);
}
export function debounce(func, delay) {
    var debounceTimer;
    return function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(function () { return func.apply(_this, args); }, delay);
    };
}
export function fuzzy(text, search) {
    var _a = [text, search].map(function (str) { return str.toLowerCase(); }), lText = _a[0], lSearch = _a[1];
    var j = 0;
    for (var i = 0; i < lText.length; i++) {
        if (lText[i] === lSearch[j])
            j++;
    }
    return j === search.length;
}
export function formatEtaToRange(baseEta) {
    var eta = baseEta || 15;
    var etaRoundedToNearest5 = Math.round(eta / 5) * 5;
    return etaRoundedToNearest5 + '-' + (etaRoundedToNearest5 + 10);
}
export function normalizeText(value) {
    return value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace("'", '');
}
export function isNullOrEmpty(stringInput) {
    return stringInput == null || stringInput.trim() === '';
}
export function getPercentageDiff(current, past) {
    if (!current || !past)
        return null;
    var percentage = Math.round((current / past) * 100) - 100;
    return {
        line: "".concat(percentage > 0 ? '+' : '-', " ").concat(Math.abs(percentage), " %"),
        color: percentage > 0 ? 'text-semantic-green' : 'text-error'
    };
}
export function getCurrencyIcon(currencyCode) {
    switch (currencyCode) {
        case 'USD':
            return 'dollar';
        case 'GBP':
            return 'pound';
        default:
            return 'euro';
    }
}
export function toKebabCase(text) {
    return text
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
}
export function snakeToCamel(text) {
    return text.replace(/([-_][a-z])/g, function (group) {
        return group.toUpperCase().replace('-', '').replace('_', '');
    });
}
export function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
export function roundMinutes(minutes, multiple, floor) {
    var remainder = minutes % multiple;
    return floor ? minutes - remainder : minutes + (multiple - remainder);
}
export function taxIdIsValid(taxId) {
    return VATValidator.isValid(taxId);
}
export function isIp(ip) {
    var ipRegex = /^192\.168\.\d{1,3}\.\d{1,3}$/;
    var ipRegex2 = /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
    var ipRegex3 = /^127\.0\.0\.1$/;
    var ipRegex4 = /^172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3}$/;
    var numbers = ip.split('.').map(Number);
    return ((ipRegex.test(ip) ||
        ipRegex2.test(ip) ||
        ipRegex3.test(ip) ||
        ipRegex4.test(ip)) &&
        numbers.every(function (number) { return number >= 0 && number <= 255; }));
}
export function isUuid4(text) {
    var uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(text);
}
export function isEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
export function toChunks(array, chunkSize) {
    var chunks = [];
    for (var i = 0, j = array.length; i < j; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
}
export function poll(_a) {
    return __awaiter(this, arguments, Promise, function (_b) {
        var i, result;
        var fn = _b.fn, _c = _b.n, n = _c === void 0 ? 10 : _c, _d = _b.interval, interval = _d === void 0 ? 1000 : _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    i = 0;
                    _e.label = 1;
                case 1:
                    if (!(i < n)) return [3 /*break*/, 4];
                    return [4 /*yield*/, fn()];
                case 2:
                    result = _e.sent();
                    if (result) {
                        return [2 /*return*/, result];
                    }
                    i++;
                    return [4 /*yield*/, sleep(interval)];
                case 3:
                    _e.sent();
                    return [3 /*break*/, 1];
                case 4:
                    if (i === n)
                        throw new Error('Polling timeout');
                    return [2 /*return*/];
            }
        });
    });
}
export function earliest(date1, date2) {
    if (!date1)
        return date2;
    if (!date2)
        return date1;
    return moment(date1).isBefore(date2) ? date1 : date2;
}
export function oldest(date1, date2) {
    if (!date1)
        return date2;
    if (!date2)
        return date1;
    return moment(date1).isBefore(date2) ? date2 : date1;
}
export function getSameLaboralDayFromLastYear(today) {
    var oneYearAgo = moment(today)
        .subtract(1, 'years')
        .week(moment(today).week())
        .day(moment(today).day());
    var oneYearAndOneDayAgo = moment(oneYearAgo).subtract(1, 'day');
    return {
        oneYearAgoAsString: oneYearAgo.format(),
        oneYearAndOneDayAgoAsString: oneYearAndOneDayAgo.format()
    };
}
export function unique(items) {
    var dict = items.reduce(function (res, item) {
        res[item.id] = item;
        return res;
    }, {});
    return Object.values(dict);
}
export function index(array, indexValue) {
    return array.reduce(function (obj, item) {
        if (item[indexValue] === undefined)
            throw new Error("Key ".concat(String(indexValue), " not found in array"));
        obj[item[indexValue]] = item;
        return obj;
    }, {});
}
export function indexList(array, indexValue) {
    return array.reduce(function (obj, item) {
        if (item[indexValue] === undefined)
            throw new Error("Key ".concat(String(indexValue), " not found in array"));
        if (!obj[item[indexValue]])
            obj[item[indexValue]] = [];
        obj[item[indexValue]].push(item);
        return obj;
    }, {});
}
export function addBusinessDays(date, days) {
    var newDate = moment(date);
    while (days > 0) {
        newDate.add(1, 'days');
        if (newDate.isoWeekday() < 6)
            days--;
    }
    return newDate;
}
export function subtractBusinessDays(date, days) {
    var newDate = moment(date);
    while (days > 0) {
        newDate.subtract(1, 'days');
        if (newDate.isoWeekday() < 6)
            days--;
    }
    return newDate;
}
export function pick(obj) {
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    return keys.reduce(function (acc, key) {
        if (key in obj) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}
export function promiseAll(promises, options) {
    return __awaiter(this, void 0, Promise, function () {
        var chunkSize, results, chunks, _i, chunks_1, chunk, chunkResults;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    chunkSize = (_a = options === null || options === void 0 ? void 0 : options.chunkSize) !== null && _a !== void 0 ? _a : 10;
                    results = [];
                    chunks = toChunks(promises, chunkSize);
                    _i = 0, chunks_1 = chunks;
                    _b.label = 1;
                case 1:
                    if (!(_i < chunks_1.length)) return [3 /*break*/, 4];
                    chunk = chunks_1[_i];
                    return [4 /*yield*/, Promise.all(chunk)];
                case 2:
                    chunkResults = _b.sent();
                    results.push.apply(results, chunkResults);
                    _b.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/, results];
            }
        });
    });
}
export function promiseAllSettled(promises, options) {
    return __awaiter(this, void 0, Promise, function () {
        var chunkSize, results, chunks, _i, chunks_2, chunk, chunkResults;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    chunkSize = (_a = options === null || options === void 0 ? void 0 : options.chunkSize) !== null && _a !== void 0 ? _a : 10;
                    results = [];
                    chunks = toChunks(promises, chunkSize);
                    _i = 0, chunks_2 = chunks;
                    _b.label = 1;
                case 1:
                    if (!(_i < chunks_2.length)) return [3 /*break*/, 4];
                    chunk = chunks_2[_i];
                    return [4 /*yield*/, Promise.allSettled(chunk)];
                case 2:
                    chunkResults = _b.sent();
                    results.push.apply(results, chunkResults);
                    _b.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/, results];
            }
        });
    });
}
export function isFuzzyMatch(target, query) {
    var normalizedTarget = target.trim().toLowerCase();
    var normalizedQuery = query.trim().toLowerCase();
    var index = 0;
    for (var _i = 0, normalizedTarget_1 = normalizedTarget; _i < normalizedTarget_1.length; _i++) {
        var char = normalizedTarget_1[_i];
        if (char.localeCompare(normalizedQuery[index], 'es', {
            sensitivity: 'base'
        }) === 0) {
            index++;
            if (index === normalizedQuery.length) {
                return true;
            }
        }
    }
    return false;
}
export function split(array, condition) {
    return array.reduce(function (acc, item) {
        if (condition(item)) {
            acc[0].push(item);
        }
        else {
            acc[1].push(item);
        }
        return acc;
    }, [[], []]);
}
