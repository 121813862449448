const adyenErrors = {
  422: { translation: 'paymentErrors.invalid-iban', needsRetry: false },
  2: { translation: 'paymentErrors.refused', needsRetry: true },
  5: { translation: 'paymentErrors.blocked-card', needsRetry: false },
  6: { translation: 'paymentErrors.expired-card', needsRetry: false },
  8: { translation: 'paymentErrors.invalid-card', needsRetry: false },
  11: { translation: 'paymentErrors.3d-not-authenticated', needsRetry: true },
  12: { translation: 'paymentErrors.not-enough-balance', needsRetry: true },
  29: {
    translation: 'paymentErrors.withdrawal-amount-exceeded',
    needsRetry: true
  },
  default: { translation: 'paymentErrors.payment-error', needsRetry: true }
}

const adyenChargeBackErrors = {
  MD01: { translation: 'paymentErrors.no-mandate', needsRetry: false }, //NoMandate
  AM04: { translation: 'paymentErrors.insufficient-funds', needsRetry: false }, //InsufficientFunds
  MS02: { translation: 'paymentErrors.refused', needsRetry: false }, //Refusal by the Debtor
  AC06: { translation: 'paymentErrors.blocked-account', needsRetry: false }, //BlockedAccount
  AC04: { translation: 'paymentErrors.closed-account', needsRetry: false }, //ClosedAccount
  SL01: { translation: 'paymentErrors.bank-blocked', needsRetry: false } //Bank is blocking any Direct Debit which is not pre-approved by the bank account holder.
}

const providers = {
  adyen: { ...adyenErrors, ...adyenChargeBackErrors }
}

const getError = (provider, error) => {
  if (!providers[provider]) throw new Error('Provider not found')
  return providers[provider][error] || providers[provider].default
}

const getTranslation = (provider, error) => {
  return getError(provider, error).translation
}

const isRetryable = (provider, error) => {
  return getError(provider, error).needsRetry
}

export default {
  getTranslation,
  isRetryable
}
